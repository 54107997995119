import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import { devicesActions } from '../store';
import { useEffectAsync } from '../reactHelper';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@material-ui/core/Button';
import Paper from '@mui/material/Paper';
import Moment from 'react-moment';
import moment from 'moment';
import Chip from '@mui/material/Chip';

import SignalCellular0BarTwoToneIcon from '@mui/icons-material/SignalCellular1BarTwoTone';
import { green, red, yellow, blue } from '@material-ui/core/colors';
import SignalCellular4BarTwoToneIcon from '@mui/icons-material/SignalCellular4BarTwoTone';
import SignalCellular2BarTwoToneIcon from '@mui/icons-material/SignalCellular2BarTwoTone';

const useStyles = makeStyles((theme) => ({
  list: {
    maxHeight: '100%',
  },
  listInner: {
    position: 'absolute',
    margin: theme.spacing(1.5, 0),
  },
}));

const PositionsView = ({ positionId }) => {
  const [position, setPosition] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [_positionId, set_PositionId] = React.useState(null)

  const [alamat, setAlamat] = React.useState("Loading...")
  // const [alamat, ] = useState("Loading...")
  const getPosition = () => {
    setLoading(true)

    fetch(`/api/positions?id=${positionId}`)
      .then(async (response) => {
        const position = await response.json()
        setPosition(position[0])
      }).catch(e => {
        //error
        // console.log(e)
      }).finally(() => setLoading(false))

  }

  const getAlamat = () => {
    setLoading(true)
    fetch(`/api/positions?id=${positionId}`)
      .then(async (response) => {
        const position = await response.json()        
        fetch("https://revgeocode.search.hereapi.com/v1/revgeocode?at=" + position[0].latitude + "%2C" + position[0].longitude + "&lang=en-US&apiKey=cAKLnhF0TcZ770-9fDQTsj30Jz25kvC4lQfOAncwJGE")
          .then(async (response) => {
            const alamats = await response.json()
            // console.log(alamats)
            setAlamat(alamats.items[0].address.label)
          }).catch(e => {

          }).finally(() => setLoading(false))


      }).catch(e => {
      }).finally(() => setLoading(false))

  }

  useEffect(() => {
    if (positionId) {
      getAlamat()
    }
  }, [alamat])

  useEffect(() => {
    if (positionId) {
      getPosition()
    }
  }, [_positionId])

  useEffect(() => {
    if (positionId !== _positionId) {
      set_PositionId(positionId)
    }
  }, [positionId])
  return (
    <div>
      {loading && `Loading..`}
      {position && (
        <>
            <table>
              <tr>
                <td><Chip label="Signal GPS"/></td>
                <td>:</td>
                <td>{!moment().diff(moment(position.fixTime), 'seconds') ? <SignalCellular0BarTwoToneIcon style={{ color: red[500] }} /> : moment().diff(moment(position.fixTime), 'seconds') <= 60 ? <SignalCellular4BarTwoToneIcon style={{ color: green[500] }} /> : moment().diff(moment(position.fixTime), 'seconds') <= 3600 ? <SignalCellular2BarTwoToneIcon  style={{ color: yellow[700] }} /> : moment().diff(moment(position.fixTime), 'seconds') <= 86400 ? <SignalCellular0BarTwoToneIcon style={{ color: red[500] }} /> : <SignalCellular0BarTwoToneIcon style={{ color: red[500] }} />} 
                
                </td>
                {!position.valid? <><Chip label="No Valid"/></> : <><Chip color='secondary' label="Valid"/></> }
                
              </tr>
              <tr>
                <td><Chip label="Posisi Terakhir"/></td>
                <td>:</td>
                <td><Chip label={<Moment fromNow>{position.fixTime}</Moment>}/>  </td>
                <td></td>
              </tr>

             
              <tr>
                <td><Chip label="Mesin"/></td>
                <td>:          </td>
                <td>
                {!position.attributes.ignition? <><Chip label="OFF"/></> : <><Chip label="ON"/></>}
                  </td>
              </tr>

              <tr>
                <td><Chip label="Kecepatan"/></td>
                <td>:          </td>

                <td>
                <Chip label={position.speed.toFixed(2)+" km/h"}/>  
                  </td>
                {!position.attributes.motion? <><Chip label="Diam"/></> : <><Chip color='secondary' label="Bergerak"/></>}
              </tr>
              <tr>
                <td><Chip label="Arah"/></td>
                <td>:</td>
                <td><Chip label={position.course}/></td>
                <td></td>
              </tr>
              <tr>
                <td><Chip label="Koordinat"/></td>
                <td>:</td>

                <td><Chip label={position.latitude.toFixed(5)+","+position.longitude.toFixed(5)}/></td>
                <td></td>
              </tr>
              {/* <tr>
                <td>Protocol</td>
                <td>:</td>

                <td>{position.protocol}</td>
                <td></td>
              </tr> */}
              <tr>
                <td><Chip label="Alamat"/></td>
                <td>:</td>

                <td>{alamat}</td>
                <td></td>
              </tr>
              <tr>
                <td>Link</td>
                <td>:</td>

                <td><a href={"http://maps.google.com/maps?f=q&q=" +position.latitude + "," + position.longitude + "&z=16"} target="_blank">Ke Google Maps</a></td>
                <td></td>
              </tr>
            </table>
            
        </>
      )}

    </div>
  )
}

const DeviceList = ({ devices, positionId }) => {
  
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);};
  const classes = useStyles();
  const dispatch = useDispatch();
  const listInnerEl = useRef(null);

  if (listInnerEl.current) {
    listInnerEl.current.className = classes.listInner;
  }

  const [, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    } else {
      throw Error(await response.text());
    }
  }, []);

 

  return (
    <Paper style={{ maxHeight: '85%', overflow: 'auto', position: 'absolute', width: '100%' }}>
    {/* {console.log(devices)} */}
    { devices && devices.map((device, index, list) => (
     <Accordion key={index} expanded={expanded === device.id} onChange={handleChange(device.id)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{ width: '100%', flexShrink: 0 }}
        >
          <Typography style={{ width: '100%', flexShrink: 0,  margin: '10px' }}>
          
          {device.name}
           {/* {device.status === "online" ? <> <Chip variant="outlined" style={{backgroundColor:'green'}} color='secondary' label="Online"/> </> : <><Chip variant="outlined" label="Offline"/> </>} */}
          </Typography>

        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <Button onClick={() => dispatch(devicesActions.select(device.id))&&setExpanded(false)} variant="contained" color="primary">-Lihat Lokasi-</Button>
        
          <br/>
          <br/>
                  <table>
                  <tr>
                      <td><Chip label="Signal Kartu"/> </td>
                      <td>:          </td>
                      <td>
                          {device.status === "online" ? <> <Chip color='secondary' label="AKTIF"/> </> : <><Chip label="DELAY"/> </>}
         
                        {/* {device.status === "online" ? <p><SimCardIcon style={{ color: blue[500] }}/> Online</p>: <p><SignalCellularOffIcon style={{ color: red[500] }} /> Offline</p>} */}
                        </td>
                      <td></td>
                    </tr>

                    <tr>
                      <td><Chip label="Identifier"/></td>
                      <td>:</td>
                      <td>
                      <Chip label={device.uniqueId}/> 
                      </td>
                      <td></td>
                    </tr>
                    <tr border="1">
                      <td><Chip label="No Telp"/></td>
                      <td>:</td>
                      <td>
                      <Chip label={device.phone}/> 
                        </td>
                      <td></td>
                    </tr>
                  </table>
                
          {device.positionId == 'undefined'? null :  <PositionsView positionId={device.positionId} /> }
          </Typography>
          
        </AccordionDetails>
      </Accordion>
      ))}
     </Paper>
    
  );
};

export default DeviceList;
