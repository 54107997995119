import {
  grey, green, common,
} from '@mui/material/colors';

const colors = {
  white: common.white,
  background: common.blue,
  primary: '#0000FF',
  secondary: green[500],
  positive: '#4CAF50',
  medium: common.blue,
  negative: grey[600],
  neutral: '#FF0000',
  geometry: '#3bb2d0',
};

export default {
  background: {
    default: colors.background,
  },
  primary: {
    main: colors.primary,
  },
  secondary: {
    main: colors.secondary,
    contrastText: colors.white,
  },
  colors,
};
